import React, { useCallback,useState,lazy, Suspense, useEffect } from "react";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import * as Globals from "./config/GLOBALS.js";
import MenuIcon from '@mui/icons-material/Menu';
import Alert from '@mui/material/Alert';
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import {submitDeliveryIssue, checkRecaptcha} from "./api/Functions.js";
import styled1 from "styled-components";
import actions from "./actions.js";
import ContactForm from './ContactForm';

import validator from 'validator';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import "../styles.css";
import Button from '@mui/material/Button';
import i18n from "./i18n.ts";
import { useTranslation } from "react-i18next";
import { blue } from '@mui/material/colors';
import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import Tooltip from '@mui/material/Tooltip';
import PublicIcon from '@mui/icons-material/Public';
//import PublicIcon from 'https://www.${Globals.SERVER_URL}/erapid.svg';
import MenuItem from '@mui/material/MenuItem';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import * as Constants from "./config/config.js";

import { AppBar, Typography, Toolbar,Grid ,Card } from '@mui/material';

import { useDispatch, useSelector} from "react-redux";

import "./css/header.css";









const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  resize: none;
  max-height:150px;
  min-height:100px;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

const StyledDiv = styled1.div`
    font-family: Arial, sans-serif;
      font-size: 14px;
      background-color: #ffdddd;
       padding: 15px;
       margin-top: 20px;
       border: 1px solid #ff8888;
       color: black;
       text-decoration: none;
       cursor: pointer;



`;

const StyledP = styled1.p`
    font-family: Arial, sans-serif;
      font-size: 14px;
      background-color: #ffdddd;
       color: black;
       text-decoration: none;
       cursor: pointer;



`;

const ResponsiveAppBar =(props) => {



  const [open, setOpen] = useState(false);
  const [token, setToken] = useState(false);
  const [orderNum, setOrderNum] = useState("");
  const [describe, setDescribe] = useState("");
  const [email, setEmail] = useState("");
  const [msgSuccess, setmsgSuccess] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [error, setError] = useState("");

  const [terms, setTerms] = useState(false);
  const [fullName, setFullName] = useState("");
  const [formValid, setFormValid] = useState(false);
  const applyNowButton = useSelector((state) => state.flightSearch.applyNowButton);
  const dispatch = useDispatch();





  const handleReCaptchaVerify = useCallback(async () => {

      //console.log("ANANA")
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }

      const token =  await executeRecaptcha('submitDeliveryIssue');

      let data ={}

      data = {

        token: token
      }



      setToken(token);
      //console.log(token)


    }, [executeRecaptcha])



const handleSubmit = async () => {




      //console.log(token)
      let data = {
       token: token,
       delivery_issue_name: fullName,
       delivery_issue_email: email,
       delivery_issue_order_num:  orderNum,
       delivery_issue_describe:  describe,

     }

     //console.log(data)



      let ret=await submitDeliveryIssue(data)
      console.log(ret.status)
      if (ret.status==200)
      {
        setmsgSuccess(true)

        //setOpen(false)
      }

      if (ret.status==500)
          setError("recaptcha error");

    };
  // Create an event handler so you can call the verification on button click event or form submit


  const handleClickOpen = () => {
    dispatch({
        type: actions.SET_APPLY_NOW_BUTTON,
        data: false,

      });
    setOpen(true);
  };

  const handleClose = (value: string) => {

    dispatch({
        type: actions.SET_APPLY_NOW_BUTTON,
        data: true,

      });
    setOpen(false);

  };


  const handleError = (value: string) => {

    let error1=false
    if (1)
    {

      if (!fullName)
      {
         //document.getElementById("full_name").focus();
         setError(t('enter_full_name'))
         setTerms(false)
         error1=true;
      }
      else if (!email)
      {

         //document.getElementById("email_address").focus();
         setError(t('error_select_enter_valid_email'))
          setTerms(false)
          error1=true;
      }
      else if (!orderNum)
      {

         //document.getElementById("order_num").focus();
         setError(t('enter_order_num'))
          setTerms(false)
          error1=true;
      }
      else if (!describe)
      {

         //document.getElementById("describe_issue").focus();
         setError(t('describe_issue'))
          setTerms(false)
          error1=true;
      }

      if (!error1)
         setError(t(''))

    }

  };
  useEffect(() => {
     handleReCaptchaVerify();
    /* global google */
     setwhatsapplink("https://api.whatsapp.com/send?phone=19173100929&text=Hello%2C%20I%20need%20help%20to%20apply%20eVisa%20for%20"+Constants.PLATFORM)
    //console.log("DUDU");
  }, [handleReCaptchaVerify]);
  const userInfo = useSelector((state) => state.userInfo);
  const [whatsapplink, setwhatsapplink] = React.useState(null);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { i18n, t } = useTranslation();
  //const pages = [t('home'), t('quick_facts'),t('things_to_be_done'), t('travel_guide'), t('facts_and_figures'), t('contact_us')];
  const pages = [t('home'), t('things_to_be_done'), t('travel_guide'), t('facts_and_figures'), t('contact_us')];
  const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    //setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar sx={{backgroundColor:"black",fontColor:"white"}} position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/*<PublicIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />*/}

          <Avatar
            sx={{ width: 30, height: 30 ,display: { xs: 'none', md: 'flex' }, mr: 1 }}
            alt=""
                variant="square"
            src={`https://${Globals.SERVER_URL}/erapid.svg`}

          />

          <Typography
            variant="h5"

            color="#d6d6d6"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontColor: '#d6d6d6!important',
              fontWeight: 700,
              letterSpacing: '.3rem',
              textDecoration: 'none',
            }}
          >
          {/*Constants.TITLE*/}
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography

                      textAlign="center">
                    <a style={{color:"#000000"}} href={"#" + page.replace(/ /g,"_")}>  {page} </a>
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        {/*<PublicIcon  sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />*/}



        <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            console.log(email);
            handleClose();
          },
        }}
      >
        <DialogTitle dir={Constants.LANG=="he"?"rtl":""}>{t('delivery_issue_title')}</DialogTitle>
        <DialogContent dir={Constants.LANG=="he"?"rtl":""}>
          <DialogContentText dir={Constants.LANG=="he"?"rtl":""}>
            {t('delivery_issue_context')}
          </DialogContentText>


          <Grid item xs={12}  md={4}>
           <br/>
          <TextField
                id="full_name"
                required
                fullWidth
                inputProps={{ maxLength: 40 }}
                label={t('full_name')}
                value={fullName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const regex = /^[a-z ]+$/i;
                if (event.target.value.length>=0 && event.target.value.length<=40)
                if (event.target.value === "" || regex.test(event.target.value)) {

                    setFullName(event.target.value);

                }
              //   handleData();


                }}
                onBlur = {(event: React.ChangeEvent<HTMLInputElement>) => {

                    setTerms(false);
                   handleError();

              //    handleData();

                }}
            />
          </Grid>


          <Grid item xs={12} md={3} >
           <br/>
          <TextField
            required
             id="email_address"
             multiline
            size="large"
            label={t('email')}
            value={email}
            fullWidth
            inputProps={{ maxLength: 50 }}
            onBlur = {(event: React.ChangeEvent<HTMLInputElement>) => {
                setTerms(false);
                handleError();

              if (validator.isEmail(email)) {
                var emailFormat = /^[a-zA-Z0-9_.+]+(?<!^[0-9]*)@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
                //alert(emailFormat.test(email));
                if (emailFormat.test(email))
                    setError('')
                else {
                      setError(t('error_select_enter_valid_email'))
                }
                //    handleData();
                  } else {
                  setError(t('error_select_enter_valid_email'))
                  setFormValid(false);
                }

            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                   const regex = /^[a-z0-9@.\-_]+$/i;
                  if (event.target.value.length>=0 && event.target.value.length<=50)
                if (event.target.value === "" || regex.test(event.target.value)) {
                    setEmail(event.target.value);
                    if (validator.isEmail(email)) {
                      var emailFormat = /^[a-zA-Z0-9_.+]+(?<!^[0-9]*)@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
                      //alert(emailFormat.test(email));
                      if (emailFormat.test(email))
                          setError('')
                      else {
                            setError(t('error_select_enter_valid_email'))
                      }
              //            handleData();
                        } else {
                        setError(t('error_select_enter_valid_email'))
                        setFormValid(false);
                      }
              //        handleData();


                }

            }}

            />
         </Grid>

         <Grid item xs={12}  md={4}>
         <br/>
         <Grid item xs={12}  md={3} >
         <TextField
            required
             id="order_num"

            multiline
           size="large"
           label={(Constants.LANG=="he" && Constants.PLATFORM=="Us-il")? t('biometric'):t('order_num')}
           value={orderNum}
           fullWidth
           inputProps={{ maxLength: 20 }}
           onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const regex = /^[a-z0-9 ]+$/i;
                if (event.target.value.length>=0 && event.target.value.length<=20)
               if (event.target.value === "" || regex.test(event.target.value)) {

                 setOrderNum(event.target.value.trim());
            //      handleData();


               }

           }}
           onBlur = {(event: React.ChangeEvent<HTMLInputElement>) => {
               setTerms(false);
               handleError()

          //   handleData();

           }}

           />
        </Grid>
         <br/>
         <Textarea
               id="describe_issue"
              rows={50}
              maxRows={50}
              aria-label="maximum height"
              placeholder={t('describe_issue')}
               required
            //   autoFocus="autoFocus"
              // fullWidth
              // inputProps={{ maxLength: 250 }}
               label={t('describe_issue')}
               value={describe}
               onChange={(event: React.ChangeEvent<HTMLInputElement>) => {

                       const regex1 = /^[a-z ]+$/i;
                       const regex2 =/^(?!\s*$)^((?!([p|P][-. ]?[o|O].?[- ]?|post office )[b|B]([ox|OX]))(?!(\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\>|\?|\"|\;|\:)).)*$/;
                       if (event.target.value.length>=0 && event.target.value.length<=250)
                       if (event.target.value === "" || regex1.test(event.target.value) || regex2.test(event.target.value)) {

                           setDescribe(event.target.value);
                           handleError()

                       }



             //   handleData();


               }}
               onBlur = {(event: React.ChangeEvent<HTMLInputElement>) => {
                   setTerms(false);
                     handleError()

             //    handleData();

               }}
           />
         </Grid>

         <Grid item xs={12}  >
         <Box component="span" sx={{ mb:4, color:"#fbf4d6!important", border: '1px sold grey' }}>
         <Typography
           variant="subtitle1"

           component="a"

           sx={{
             mr: 2,


             fontWeight: 250,

             color: 'red',
             textDecoration: 'none',
           }}
         >
         &nbsp;
         {error}
         </Typography>
       </Box>
        {msgSuccess && <Alert severity="success">{t('your_msg_sent_suc')}</Alert>}
         </Grid>
         <StyledDiv>
          <div>
            <strong>{t('very_important_note')}</strong>
            <br/>
            <br/>
            <StyledP>{t('in_case_you_did_not')}</StyledP>
            <StyledP>{t('dear_customer_we_thank')}</StyledP>
            <StyledP>{t('it_is_important_that')}</StyledP>
            <StyledP>{t('please_do_not_dispute')}</StyledP>
            <menu>
                <li>{t('immediate_cancellation')}</li>
                <li>{t('notification_of_the_authority')}</li>
                <li>{t('notification_of_your_own')}</li>
                <li>{t('other_legal_procedural')}</li>
            </menu>
            <br/>
            <StyledP>{t('to_avoid_these_possible')}</StyledP>
            <StyledP>{t('you_can_reply_to')}</StyledP>
            <StyledP>{t('thank_you_for_your')}</StyledP>

            </div>
         </StyledDiv>
          <br/>
           {msgSuccess && <Alert severity="success">{t('your_msg_sent_suc')}</Alert>}

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('close')}</Button>
          <Button disabled={!(fullName&&email&&describe&&!error&&!msgSuccess)} onClick={ handleSubmit }>{t('submit')}</Button>
        </DialogActions>
      </Dialog>
          <Avatar
            sx={{ width: 30, height: 30,display: { xs: 'flex', md: 'none' }, mr: 1 }}
            variant="square"
            alt=""
            src={`https://${Globals.SERVER_URL}/erapid.svg`}

          />
          <Typography
            variant="h5"
            noWrap
            color="#d6d6d6"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontColor: '#d6d6d6!important',
              fontWeight: 700,
              letterSpacing: '.3rem',
              textDecoration: 'none',
            }}
          >
            {/*Constants.TITLE*/}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                size="small"
                onClick={(event, newValue) => {
                  //  console.log(page.replace(/ /g,"_"));
                    window.location.href = "#"+page.replace(/ /g,"_");


                }}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
              <Box component="span" sx={{ color:"grey", border:0 }}>
                <Typography
                  variant="caption"
                  noWrap
                  sx={{

                    //mr: 2,
                  //  display: { xs: 'flex', md: 'none' },
                    flexGrow: 1,
                  //  fontSize: "10px",
                    fontFamily: 'monospace',
                    fontWeight: 100,
                    letterSpacing: '.1rem',
                    color: 'inherit',
                    textDecoration: 'none',
                  }}
                >
                  {page}
                </Typography>
                </Box>
              </Button>
            ))}
          </Box>
          {Globals.ENABLE_DELIVERY_ISSUE && <Button
            onClick={(event, newValue) => {

              if (open)
                  handleClickOpen()
              else {
                handleClickOpen()

              }


            }}
            style={{
                borderRadius: 10,
                backgroundColor: "red",
                padding: "8px 8px",
                fontSize: "11px"
            }}
              variant="contained"
              >
            {t('delivery_issue')}
        </Button>}
          { true && <Box sx={{ flexGrow: 0 }}>

          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            { false && <WhatsAppIcon  onClick={(event, newValue) => {

              window.open("https://api.whatsapp.com/send?phone=19173100929", "_blank");


            }} sx={{
                mr: 0.5,
                  display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                paddingTop:0.1,
                fontFamily: 'monospace',
                fontWeight: 700,

                color: 'white',
                textDecoration: 'none',
              }}/>}

              { false && <Typography
                    variant="caption"
                  //  noWrap
                    component="a"

                    sx={{
                    //  mr: 2,
                      display: { xs: 'flex', md: 'none' },
                      flexGrow: 1,
                      fontFamily: 'monospace',
                      fontWeight: 100,
                      letterSpacing: '.1rem',
                      color: 'white',
                      textDecoration: 'none',
                    }}
                  > {'24/7'}  </Typography>}}




                <LiveHelpIcon   onClick={(event, newValue) => {
                  void(Tawk_API.toggle());
                }} sx={{
                    mr: 0.5,
                    ml:1,
                      display: { xs: 'flex', md: 'none' },
                    flexGrow: 1,
                    paddingTop:0.1,
                    fontFamily: 'monospace',
                    fontWeight: 100,

                    color: 'white',
                    textDecoration: 'none',
                  }} />
                  { 1 && <Typography
                        variant="caption"
                      //  noWrap
                        component="a"

                        sx={{
                        //  mr: 2,
                          display: { xs: 'flex', md: 'none' },
                          flexGrow: 1,
                          fontFamily: 'monospace',
                          fontWeight: 100,
                          letterSpacing: '.1rem',
                          color: 'white',
                          textDecoration: 'none',
                        }}
                      > {'24/7'}  </Typography>}}


            </IconButton>

          </Tooltip>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              { false && <WhatsAppIcon   sx={{
                  mr: 0.5,
                  display: { xs: 'none', md: 'flex' },
                  flexGrow: 1,
                  paddingTop:0.1,
                  fontFamily: 'monospace',
                  fontWeight: 700,

                  color: 'white',
                  textDecoration: 'none',
                }}/>  } { 0 && <Typography
                    variant="caption"
                  //  noWrap
                    component="a"
                    href={whatsapplink}
                    sx={{
                    //  mr: 2,
                      display: { xs: 'none', md: 'flex' },
                      flexGrow: 1,
                      fontFamily: 'monospace',
                      fontWeight: 100,
                      letterSpacing: '.1rem',
                      color: 'white',
                      textDecoration: 'none',
                    }}
                  >{`Whatsapp`} {'24/7'}  </Typography>}
                  <LiveHelpIcon   sx={{
                      mr: 0.5,
                      ml:1,
                      display: { xs: 'none', md: 'flex' },
                      flexGrow: 1,
                      paddingTop:0.1,
                      fontFamily: 'monospace',
                      fontWeight: 100,

                      color: 'white',
                      textDecoration: 'none',
                    }}/>   <Typography
                        variant="caption"
                      //  noWrap
                        component="a"
                        onClick={(event, newValue) => {
                          void(Tawk_API.toggle());
                        }}
                      //  href="https://api.whatsapp.com/send?phone=19173100929&text=Hi,%20i%20just%20visited%20your%20website%20and%20i%20have%20some%20queries%20related%20to%20it."
                        sx={{
                        //  mr: 2,
                          display: { xs: 'none', md: 'flex' },
                          flexGrow: 1,
                          fontFamily: 'monospace',
                          fontWeight: 700,
                          letterSpacing: '.1rem',
                          color: 'white',
                          textDecoration: 'none',
                        }}
                      >{t('live_chat')} {'24/7'} </Typography>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              { userInfo.result && (
                <Box sx={{ m: 2  ,fontWeight: 'medium', fontSize: 'small',  border: 1}} >
                <Typography       variant="subtitle"
                  //  noWrap
                    component="a"
                    color="green"
                    href=""
                    sx={{
                      mr: 2,
                      mx:1,
                      //display: { xs: 'flex', md: 'none' },
                      flexGrow: 1,
                      fontFamily: 'monospace',
                      fontWeight: 700,
                      //letterSpacing: '.3rem',
                      color: 'green',
                      textDecoration: 'none',
                    }} >{ userInfo.result && ('Credits:'+userInfo.result.credits) }


                </Typography>
                </Box>)

              }
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
            {/*<Typography>&nbsp;{`ALERTAIR - Pushing Alerts From Air`}</Typography>
            <Grid alignItems="right">
            <Typography  color="white">&nbsp;{ userInfo.result && (userInfo.result.fname+" "+userInfo.result.lname) }</Typography>

            </Grid>*/}
          </Box>}
        </Toolbar>
      </Container>
    </AppBar>
  );
}



export default ResponsiveAppBar;
