import * as Constants from "./config.js";
function randomIntFromInterval(min, max) { // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

var randomBool = (function() {
  var a = new Uint8Array(1);
  return function() {
    crypto.getRandomValues(a);
    return a[0] > 127;
  };
})();



export const STRIPE=false
export const PAYPAL=true
export const EMERGENCY_COST=50
let result=""
if (Constants.SHOW_PRICE=="random")
  result=randomBool()
else
  result=Constants.SHOW_PRICE

//Override
//result=true
result=randomBool()

export const REVEAL_PRICE=result
//export const REVEAL_PRICE=false
//export const REVEAL_PRICE=randomBool()
//export const REVEAL_PRICE=false
export const SHOW_COST=false
export const SHOW_COST_PAYMENT_ONLY=false
export const SHOW_TBD=true
export const SITE_KEY="6Ldk3jgpAAAAADWFJRXNB1liCa7-CcvcyaPC4Cmp"
//export const SERVER="GOVISA.AI"
//export const SERVER_URL="govisa.ai"
export const SERVER="GOVIS.AI"
export const SERVER_URL="govis.ai"
export const GUIDED=[]
//ZAATARIUS
var STRIPE1="pk_live_51Jv0dsKtGhJNikuOSlUnTmLr6KqY6ca3NZaod2Zivz9i8qlnQkchQIPszxnJZV9RFbatGoqogCK9TuvTY42xeavF00hQbxyyIz"
var STRIPE1_PORT=8443
//TZEDEK TECH
//var STRIPE2="pk_live_51Pqjub03ZwuhgfYckREkX4CP1xYRBO3ZB66Q2UXuhsRenzZjTxWhEw8IQNGz9cKl2rxNH05ZSDVvf9LO8T4gxjpY00GoQMBBQ1"
//var STRIPE2_PORT=8444

//DAVEST HOLDINGS
var STRIPE2="pk_live_51Prk2FP3ioXswJm5781Jger7nZPma8b76EAZmd5GpszBsr2cE3Df0NLZEu0d7AoaW5rw4SDiJHc6JwxwkeA5OiKR00jHIfIsaB"
var STRIPE2_PORT=8444


//KOSHERWHERE
var PAYPAL1="AWExa0pfnVZxPiUX1U3P-RlACs2gzfDSRP67_zKDio2ubZMp6rHDp90dB4qTENh5Gujq9C8a_EweaAxD"
//DAVEST HOLDINGS PayPal
var PAYPAL2="AXVRX3igl_CLo7aZVcjEL3kY7kKhlzrCMSnIqTGxdho6ki4DLxOZpCgXtEntXOVVMq0xWimXRIULKMEG"


let which_paypal=""
let which_stripe=""
if (Constants.WHICH_STRIPE=="random")
  which_stripe=randomIntFromInterval(1, 2);
else
  which_stripe=Constants.WHICH_STRIPE

var STRIPE_CLIENT_=""
var PAYPAL_CLIENT_=""
var STRIPE_PORT_=""
var SERVER_IP_=""


///OVERRIDE////
//which_stripe=randomIntFromInterval(1, 2);
which_stripe=2
which_paypal=1

if (which_stripe==1)
{
   STRIPE_CLIENT_=STRIPE1
   STRIPE_PORT_=STRIPE1_PORT
   SERVER_IP_=SERVER_URL
}
else if (which_stripe==2)
{
   STRIPE_CLIENT_=STRIPE2
   STRIPE_PORT_=STRIPE2_PORT
   SERVER_IP_="govisa.ai"
}



if (which_paypal==1)
{
   PAYPAL_CLIENT_=PAYPAL1

}
else if (which_paypal==2)
{
   PAYPAL_CLIENT_=PAYPAL2

}

//alert(which_stripe)
export const STRIPE_CLIENT=STRIPE_CLIENT_
export const PAYPAL_CLIENT=PAYPAL_CLIENT_
export const STRIPE_PORT=STRIPE_PORT_
export const SERVER_IP=SERVER_IP_

export const STRIPE_TEST_KEY="pk_test_51Jv0dsKtGhJNikuOpV0iIDmxHSNN8LExlowDtHyFCFK2U9LyA13qG9ZCTOXPrjR8H8t9I1Z12mS5Gu6A1aLMjACX00k2kKYRRo"
export const PAYPAL_TEST_KEY="AZpUuzTqEKQyLk3Y4ZP8TONSpckYREOBQn1uaB8CmnMBRIvMtNCzTYpV9EVOrLJ4cRoYlTd7ip-1oIJ6"

export const TEST_ALLOW_IP="2a01:cb00:f:7500:89b2:f4fc:c02e:844f"

export const PAYPAL_PAYMENT="Paypal/Credit Card"
export const STRIPE_PAYMENT="Credit/Debit Card"
export const DEFAULT_PAYMENT=STRIPE_PAYMENT


export const ENABLE_DELIVERY_ISSUE=false
//export const SERVER_URL="aitrvl.com"
//export const SERVER="AI.TRVL"
